import type { AllPagesQuery } from '@/.generated/sanityTypes/graphql'
import React from 'react'

import Layout from '@/components/layout'
import Card from '@/components/card'
import { useTranslations } from 'next-intl'
import type { GetStaticPropsContext, GetStaticPropsResult } from 'next/types'
import { getAllPages } from '@/lib/query'

type NotFoundProps = {
  messages: unknown
  allPages: AllPagesQuery
}

const NotFound = ({ allPages }: NotFoundProps) => {
  const t = useTranslations('pageNotFound')

  return (
    <Layout allPages={allPages} title={t('title')}>
      <Card>
        <h2 className="my-10 text-center text-xl sm:text-6xl">{t('title')}</h2>
      </Card>
    </Layout>
  )
}

export async function getStaticProps(
  context: GetStaticPropsContext,
): Promise<GetStaticPropsResult<NotFoundProps>> {
  return {
    props: {
      messages: (await import(`@/translation/${context.locale}.json`)).default,
      allPages: await getAllPages(),
    },
  }
}

export default NotFound
